<template>
    <div class="main_content">
        <div >
            <div class="left">
            <div class=" wow slideInDown title">
                    厦门历思科技服务有限公司
            </div>
            <div class="content  wow slideInDown">
                厦门历思科技服务有限公司（简称历思）是一家拥有多专业物证优势技术的国家级高新技术企业，主要从事研发与技术创新、投融资与集团化管理，为历思体系内机构提供业务与发展支撑。
</div>
            <div class="more  wow slideInLeft" @click="more">
                <p class="blue">了解更多</p>
                <p class="blue">- ></p>
            </div>
            
            </div>
            <div class="footer-box">
                <div class="footer">
                  <p>地址：福建省厦门市湖里区湖里大道33号7层西侧</p>
                  <p>版权所有： 厦门历思科技服务有限公司|备案号 闽ICP备2021011987号-1</p>
                </div>
            </div>
            <Side class="img-box"></Side>
        </div>
        <div class="main">
        </div>
        <div class="main">
        </div>
    </div>
</template>
<script>
import Side from "@/components/Side";
var main_el1 = null;
var main_el2 = null;
export default {
    components:{
        Side
    },
    data(){
        return {
          imgData : [
            'bg1.jpg','bg2.jpg','bg3.jpg',
          ],
          index1 : 2,
          index2 : 1,
        }
    },
    mounted(){
        

        main_el1 = document.getElementsByClassName('main')[1]
        main_el2 = document.getElementsByClassName('main')[2]
        main_el1.style.background= "url("+require('../assets/home/bg1.jpg')+")"
        main_el1.className ='main animation'
        
        const _this = this
        this.setTimer(0)
        setTimeout(function(){
          // 10秒清除动画
          main_el1.className = 'main'
          main_el1.style.background = "url('')"
        },10000)
        setTimeout(function(){
          
          
          _this.setTimer(1,function(){
            main_el2.className ='main animation'
            main_el2.style.background= "url("+require('../assets/home/bg2.jpg')+")"
            setTimeout(function(){
              // 10秒清除动画
              main_el2.className = 'main'
              main_el2.style.background = "url('')"
            },10000)
          })
        },8000)
    },
    methods:{
      setTimer(type,callback=''){
        const _this = this
        console.log(typeof callback)
        if(typeof callback == 'function'){

          callback()
        }
        setInterval(function(){
          let main_el = null;
          let data = _this.imgData
          let index1 = _this.index1
          let index2 = _this.index2
          // 16秒的循环
            
          let index= null;
              // 16秒添加动画并更换图片
            switch(type){
              case 0:
                index = index1
                main_el = main_el1
                _this.index1 = index1+2 >= data.length ? 0: index1+2
              break;
              case 1:
                index = index2
                main_el = main_el2
                _this.index2 = index2+2 > data.length-1 ? 1: index2+2
              break;
            }
            setTimeout(function(){
              // 10秒清除动画
              main_el.className = 'main'
              main_el.style.background = "url('')"
            },10000)
            main_el.className = 'main animation'
            main_el.style.background= "url("+require('../assets/home/'+data[index])+")"
            
        },16000)
      },
        more(){
            this.$store.commit("set_path",{path:'/goin/introduct',query:{}})
            // console.log(this.$router)
            this.$store.commit('set_Module','/goin')

        }
    }
}
</script>
<style scoped src="@/common.css"></style>
<style scoped>
.animation {
    /* background: url('../assets/home/bg1.jpg'); */
    height: 100vh;
    width: 100vw;
    background-size: cover!important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    /* animation: scale_b 25s linear infinite alternate; */
    position: absolute;
    z-index:0;
    top:0;
    margin-top: 49px;
    animation: fateimg 10s linear 1;
    max-width: 100%;
}
.main_content{
    position:fixed;
    height:100vh;
    top:0;
    left:0;
    width:100vw;
}
@keyframes fateimg{
    0%{opacity: 0;}
    5%{opacity: 0.3;}
    40%{opacity: 0.8;}
    45%{opacity: 1;}
    80%{opacity: 1;}
    85%{opacity: 0.8;}
    90%{opacity: 0.4;}
    100%{opacity: 0;}
}
.left{
    width:65%;
    text-align:left;
    /* min-width:300px; */
    padding-top: 2vh;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}
.title{
    font-size: 2rem;
    /* background-color: rgba(0,0,0,0.1); */
    color: #fff;
    padding: 15px 10px;
    width: max-content;
    margin-top: 20vh;
    
}
.content{
    max-width: 578px;
    font-size: 1rem;
    line-height: 2rem;
    /* background-color: rgba(0,0,0,0.1); */
    margin-top: 6vh;
    margin-bottom: 8vh;
    color: #fff;
    padding: 10px 10px;
    text-indent: 4rem;
    width: 62%;
    min-width: 236px;
}
.blue{
    color:#fff;
    font-size:0.8rem;
    background-color:#0c65cc;
    line-height:40px;
    height:40px;
    margin-right:2rem;
    border-radius: 2px;
    padding: 0 11px;
}
.more{
    display:flex;
}
.footer{
    color: #fff;
    font-size:0.8rem;
}
.footer-box{
    width:100%;
    position:fixed;
    bottom:0;
    height:3rem;
    z-index: 100;

}
.img-box{
    z-index: 2;
}
</style>